import Alpine from 'alpinejs';
export default class Flash {
  #eventBus
  constructor(eventBus) {
    this.#eventBus = eventBus
    this.bind()
    Alpine.store('flashNotice', '')
    Alpine.store('flashError', '')
  }

  bind() {
    this.#eventBus.on('flash:notice', (message) => this.set('notice', message))
    this.#eventBus.on('flash:error', (message) => this.set('error', message))
  }

  set(type, message) {
    const key = `flash${type.charAt(0).toUpperCase() + type.slice(1)}`
    Alpine.store(key, { type, message })
  }

  initCarousel({el, options = {}}) {
    this.instances[el] = new Splide(el, {
      pagination: false,
      ...options
    }).mount();
  }

  get notice() {
    return Alpine.store('flashNotice')
  }
  get error() {
    return Alpine.store('flashError')
  }
}
