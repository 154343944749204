// Description: Improves the accessibility of embedded videos by adding an aria-label to the play button
function improveVideoA11y() {
  [ ...document.querySelectorAll('lite-vimeo, lite-youtube')].forEach(video => {
    const title = video.getAttribute('aria-label')

    if (title) {
      video.querySelector('button')?.setAttribute('aria-label', `Play ${title}`)
    }
  })
}

document.addEventListener('DOMContentLoaded', improveVideoA11y)
