import Alpine from 'alpinejs'

/**
 * Manages Event Search filters + async fetching of results
 *
 * @see src/templates/_degree-dates.twig
 * @see src/modules/frontend/controllers/EventSearchController.php
 *
 * @property $refs
 * @property $store
 */
export default ({
  // initial state is passed in from the template, which in turn gets it from the controller
  allValleys,
  dateRange,
  dateRanges,
  defaultDateRange,
  degree,
  degrees,
  onlyDegrees,
  eventType,
  eventTypes,
  state,
  states,
  valley,
  filters = [],
  }) => {
  return {
/****** Start data ******/
    desktopBreakpoint: '(min-width: 768px)', // TW md
    isDesktop: false, // is the current screen a desktop class width?
    isLoading: false, // is an update is in progress?
    mobileFiltersOpen: false, // are the mobile filters are open?
    onlyDegrees, // only show degree events
    dateRange, // the currently selected date range
    dateRanges, // the available date ranges
    defaultDateRange, // the default date range
    degree, // the currently selected degrees
    degrees, // the available degrees
    eventType, // the currently selected event types
    eventTypes, // the available event types
    state, // the currently selected states
    states, // the available states
    valley, // the currently selected valleys
    allValleys, // all the available valleys - valleys is a getter because it gets filtered by state when one is applied
    filters, // the available filter config
/****** End of data ******/
    /**
     * Initialize the component
     */
    init() {
      // watch for changes to the filter form and fetch results
      ['dateRange', 'degree', 'eventType', 'state', 'valley', 'onlyDegrees'].forEach(key => {
        this.$watch(key, Alpine.throttle(async () => {
          await this.updateResults()
        }, 100))
      })
      this.watchBreakpoint()
      // when state selection changes, filter out non-permitted valleys
      this.$watch('state', () => {
        this.valley = this.valley.filter(v => this.state.includes(this.valueToOption('allValleys', v).state?.value))
      })
      // When onlyDegrees toggle changes, reset non-permitted filters
      this.$watch('onlyDegrees', (isOnlyDegrees) => {
        if (isOnlyDegrees) {
          this.eventType = []
        } else {
          this.degree = []
        }
      })
      // Close the mobile filters when the desktop breakpoint is reached
      this.$watch('isDesktop', (isDesktop) => {
        if (isDesktop) {
          this.mobileFiltersOpen = false
        }
      })
    },
/****** Start computed properties / getters ******/
    /**
     * Only permit valleys that are in the selected states (if any)
     *
     * @return {*}
     */
    get valleys() {
      return this.state.length ? this.allValleys.filter(v => this.state.includes(v.state?.value)) : allValleys
    },
    /**
     * Only enable the degree toggle if no event types are selected
     *
     * @return {boolean}
     */
    get enableDegreeToggle() {
      return this.eventType.length === 0
    },
    /**
     * Used to disable the event type filter if the degree toggle is enabled
     * @return {*}
     */
    get disableEventTypeFilter() {
      return this.onlyDegrees
    },
    /**
     * Get the total number of filters currently applied
     * @return {number}
     */
    get currentFilterCount() {
      return [
        this.dateRange !== this.defaultDateRange,
        ...this.degree,
        ...this.eventType,
        ...this.state,
        ...this.valley,
      ].filter(Boolean)
        .length
    },
/****** End of computed properties / getters ******/
/****** Start methods ******/
    /**
     * Update the results with new data from the server
     * @return {Promise<void>}
     */
    async updateResults()  {
      if (this.isLoading) {
        return
      }
      const params = this.serialize(this.$refs.filterForm)

      const url = new URL('/degree-dates/', window.location.origin)
      url.search = params.toString()
      window.history.replaceState({}, '', url)
      this.isLoading = true
      this.$refs.results.innerHTML = await this.fetch(url)
      this.isLoading = false
    },
    /**
     * Serialize a form to a URLSearchParams object
     * @param form
     * @return {module:url.URLSearchParams}
     */
    serialize(form) {
      const formData = new FormData(form)
      return new URLSearchParams(formData)
    },
    /**
     * Fetch a URL and return the response text
     * @param url
     * @return {Promise<string>}
     */
    async fetch(url)  {
      const params = new URLSearchParams(url.search)
      params.append('asFragment', 'true')
      url.search = params.toString()
      return fetch(url, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      }).then(response => response.text())
    },
    /**
     * Get the label for a listbox closed state
     * @param optionsKey
     * @param valueKey
     * @param fallback
     * @return {string}
     */
    getCurrentLabelFor(optionsKey, valueKey, fallback) {
      const options = this.arrayify(this[optionsKey]);
      return `${
        this[valueKey].length
          ? options.filter(s => this[valueKey].includes(s.value)).map(s => s.label).join(', ')
          : (fallback ?? optionsKey)
      }`;
    },
    /**
     * Get the label for the mobile filter headers, includes a summary of current state
     * (e.g. "Date Range (Next 30 Days)")
     * @param modelName
     * @param disabledModelName
     * @return {string}
     */
    filterCountLabel(modelName, disabledModelName = false) {
      const value = this[modelName]
      if (disabledModelName) {
        return `(Disabled)`
      }
      if (Array.isArray(value)) {
        return `(${value.length ? value.length : `All`} Selected)`
      }
      return `(${this.valueToLabel(this.modelToOptions(modelName), value)})`
    },
    /**
     * Convert a value (string) to an option object
     * @param options
     * @param value
     * @return {*}
     */
    valueToOption(options, value) {
      return this[options].find(v => v.value === value)
    },
    /**
     * Convert a value to a label
     * @param options
     * @param value
     * @return {*}
     */
    valueToLabel(options, value) {
      return this.valueToOption(options, value)?.label ?? value
    },
    /**
     * Unset a value from a model
     * @param model
     * @param value
     */
    removeValue(model, value) {
      this[model] = this[model].filter(v => v !== value)
    },
    /**
     * Reset the date range to the default
     */
    resetDateRange() {
      this.dateRange = this.defaultDateRange
    },
    /**
     * Convert a model name to an options key
     */
    modelToOptions(model) {
      return `${model}s`
    },
    /**
     * Handle a filter change - used for mobile filters
     * @param $event
     * @param model
     */
    handleFilterChange($event, model) {
      const {target} = $event
      const {value, checked} = target
      if (Array.isArray(this[model])) {
        if (checked) {
          this[model].push(value)
        } else {
          this[model] = this[model].filter(v => v !== value)
        }
      } else {
          this[model] = checked ? value : null
      }
    },
    /**
     * Check if a model has a given value
     * @param model
     * @param value
     * @return {boolean}
     */
    modelHasValue(model, value) {
      return this.arrayify(this[model]).includes(value)
    },
    /**
     * Convert a value to an array
     * @param value
     * @return {*|*[]}
     */
    arrayify(value) {
      return Array.isArray(value) ? value : [value]
    },
    /**
     * Clear all filters
     */
    resetAllFilters() {
      this.resetDateRange()
      this.degree = []
      this.eventType = []
      this.state = []
      this.valley = []
      this.onlyDegrees = false
    },
    /**
     * Watch for changes to the desktop breakpoint
     */
    watchBreakpoint() {
      const mq = window.matchMedia(this.desktopBreakpoint)
      this.isDesktop = mq.matches

      mq.addEventListener('change', (e) => {
        this.isDesktop = e.matches
      })
    }
  }
}
