/**
 * Queue events to be processed once the event bus is available
 *
 * - Intended to replace a `queue: []` key on window.APP global
 */
export default class Queue {
  #eventBus
  #storageKey = 'queue'
  debug = false
  constructor(eventBus, items = [], { DEBUG = false } = {}) {
    this.#eventBus = eventBus
    this.debug = DEBUG
    this.work(items)
  }

  work(items) {
    [...this.#readFromStore(), ...items].forEach((item) => this.push(item))
  }

  push({ message, data, defer = false, id }) {
    if (defer) {
      this.defer(message, data)
    } else {
      this.send(message, data, id)
    }
  }

  defer(message, data) {
    if (this.debug) {
      console.log(`[queue] Defer: ${message}`, data)
    }
    const storedQueue = this.#readFromStore()
    storedQueue.push({ message, data, id: this.#uniqueId() })
    this.#writeToStore(storedQueue)
  }

  send(message, data, id) {
    if (this.debug) {
      console.log(`[queue] Push: ${message}`, data)
    }
    if (id) {
      this.#deleteFromStore(id)
    }
    this.#eventBus.emit(message, data)
  }

  reset() {
    this.items = []
    this.#clearStore()
  }

  get stored() {
    return this.#readFromStore()
  }

  #readFromStore() {
    try {
      const items = JSON.parse(window.sessionStorage.getItem(this.#storageKey))

      return items ?? []
    } catch (error) {
      console.error(error)
      return []
    }
    return JSON.parse(window.sessionStorage.getItem(this.#storageKey)) ?? []
  }
  #writeToStore(data) {
    try {
      window.sessionStorage.setItem(this.#storageKey, JSON.stringify(data))
    } catch (error) {
      console.error(error)
    }
  }

  #deleteFromStore(id) {
    const storedQueue = this.#readFromStore()
    const index = storedQueue.findIndex((item) => item.id === id)
    if (index > -1) {
      if (this.debug) {
        console.log(`[queue] Deleting: ${storedQueue[index].message}`, storedQueue[index].data)
      }
      storedQueue.splice(index, 1)
      this.#writeToStore(storedQueue)
    }
  }

  #clearStore() {
    return window.sessionStorage.removeItem(this.#storageKey)
  }

  #uniqueId() {
    return window.crypto.getRandomValues(new Uint32Array(1))[0];
  }
}
