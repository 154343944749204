import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus'
import intersect from '@alpinejs/intersect'
import ListBox from '@alpinejs/ui/src/listbox'
import Disclosure from '@alpinejs/ui/src/disclosure'
import Dialog from '@alpinejs/ui/src/dialog'
import Switch from '@alpinejs/ui/src/switch'


import 'lite-youtube-embed'
import 'lite-vimeo-embed/module/lite-vimeo-embed'
import 'virtual:svg-icons-register'

import initSentry from './lib/sentry'
import EventBus from './lib/EventBus'
import Queue from './lib/Queue'
import './modules/VideoA11y'
import Carousels from './modules/Carousels'
import Flash from './modules/Flash'
import MemberAuth from './modules/MemberAuth'


// Alpine components
import LayoutManager from './components/LayoutManager'
import InPageNavigation from './components/InPageNavigation'
import MessageModal from './components/MessageModal'
import EventSearch from './components/EventSearch'

import.meta.glob('~/img/**/*')

Alpine.plugin(collapse)
Alpine.plugin(focus)
Alpine.plugin(intersect)
Alpine.plugin(Dialog)
Alpine.plugin(ListBox)
Alpine.plugin(Disclosure)
Alpine.plugin(Switch)

Alpine.data('layoutManager', LayoutManager)
Alpine.data('eventSearch', EventSearch)
Alpine.data('inPageNavigation', InPageNavigation)
Alpine.data('messageModal', MessageModal)

function init() {
  const APP = window.APP || {
    CONFIG: {},
    queue: [],
  }

  initSentry(APP.CONFIG.SENTRY)

  const eventBus = new EventBus()

  window.APP = {
    ...APP,
    Alpine,
    eventBus,
    // Alpine components
    components: {
      LayoutManager
    },
    // Other widgets / modules
    modules: {
      flash: new Flash(eventBus, APP.CONFIG),
      memberAuth: new MemberAuth(eventBus, APP.CONFIG),
      carousels: new Carousels(eventBus, APP.CONFIG),
    },
    queue: new Queue(eventBus, APP.queue, APP.CONFIG),
    trigger: (eventName, data)  => eventBus.emit(eventName, data),
  }

  Alpine.start()
}

window.Alpine = Alpine

window.addEventListener('DOMContentLoaded', init)

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR')
  })
}
