import Alpine from 'alpinejs'

/**
 * Member Auth Module
 *
 * Handles:
 * - display of login popup window
 * - responding to queued events after log in / log out
 */
export default class MemberAuth {
  /**
   * @property Object URLS
   * URLs for various member auth actions
   */
  URLS = {}
  /**
   * @property Boolean isProtectedPath
   * Is the current path protected by member auth? (i.e. is it a member-only page)
   * If so don't set the returnUrl to the current page, but instead to the home page
   */
  isProtectedPath = false
    /**
   * @property String returnUrlKey
   * localStorage key for tracking where to forward user to after action
   */
  returnUrlKey
  /**
   * @property EventBus #mql
   */
  #eventBus

  constructor(eventBus,
              {
                IS_PROTECTED_PATH = false,
                URLS = {
                  AFTER_LOGIN: '/after-login',
                  LOGIN_STATE: '/dynamic/member',
                  REDIRECT_WITH_FLASH: '/redirect',
                  PROTECTED_ASSET_BASE: '/protected-asset',
                },
              }) {
    this.URLS = URLS

    this.isProtectedPath = IS_PROTECTED_PATH
      this.returnUrlKey = `MemberAuth:returnUrl`
      this.#eventBus = eventBus
      this.setupMemberStore();
      this.fetchLoggedInState();
      this.bind()
    }
  bind() {
    this.#subscribe('beforeLogin', this.handleBeforeLogin.bind(this))
    this.#subscribe('beforeLogout', this.handleBeforeLogout.bind(this))

    this.#subscribe('afterLogin', this.handleAfterLogin.bind(this))
    this.#subscribe('afterLogout', this.handleAfterLogout.bind(this))
    this.#subscribe('redirect', this.redirectTo.bind(this))

    this.bindToProtectedAssetLinks()
  }
  setupMemberStore() {
    Alpine.store('currentMember', false)
    Alpine.store('requestedProtectedAsset', '')
    Alpine.store('showProtectedAssetModal', false)
    Alpine.store('memberNotifications', [])
  }
  /**
   * Fetch the logged in state from the server
   */
  async fetchLoggedInState() {
    try {
      const response = await fetch(this.URLS.LOGIN_STATE, {
        credentials: 'include',
      })

      const {
        currentMember,
        memberNotifications
      } = await response.json()

      Alpine.store('currentMember', currentMember)
      Alpine.store('memberNotifications', memberNotifications)
    }
    catch (error) {
      console.error("Couldn't fetch login state", error)
    }
  }
  /**
   * Handle login action
   */
  handleBeforeLogin(event) {
    const loginBtnUrl = new URL(event.currentTarget.href)
    const pageUrl = new URL(window.location)

    // stash the requested forwarding URL, falls back to the current page URL if not set
    const forwardingUrl = loginBtnUrl.searchParams.get('forward') ?? pageUrl.searchParams.get('forward') ?? pageUrl.pathname
    window.localStorage.setItem(this.returnUrlKey, forwardingUrl)
    // `redirect` here is a fixed param for Verbb Social Login

    this.redirectTo(loginBtnUrl)
  }
  /**
   * Handle logout action
   */
  handleBeforeLogout(event) {
    const returnUrl = this.isProtectedPath ? '/' : window.location.pathname
    window.localStorage.setItem(this.returnUrlKey, returnUrl)

    window.location = event.currentTarget.href
  }

  redirectTo(url) {
    window.location = url
  }
  /**
   * Handle After log in 
   * Called from the "special" redirect URL page at APP.CONFIG.URLS.AFTER_LOGIN
   * @param  {Object({fallbackUrl: string})}
   * @return {null}
   */
  handleAfterLogin({ fallbackUrl }) {
    const url = this.#retrieveUrl(fallbackUrl)

    url.searchParams.set('mc', 'true')

    window.localStorage.removeItem(this.returnUrlKey)

    this.#emit('login', this)

    window.location = url.toString()
  }

  /**
   * Called from the "special" redirect URL at CONFIG.URLS. for after log out to restore the old URL
   * @param  {Object({fallbackUrl: string})}
   * @return {null}
   */
  handleAfterLogout({ fallbackUrl = '/'}) {
    const url = this.#retrieveUrl(fallbackUrl)

    url.searchParams.delete('mc')

    window.localStorage.removeItem(this.returnUrlKey)

    this.#emit('logout', this)

    window.location = url.toString()
  }

  /**
   * Bind to secure asset links and show a modal if we don't have a current member
   *
   */
  bindToProtectedAssetLinks() {
    document.querySelectorAll(`a[href^="${this.URLS.PROTECTED_ASSET_BASE}"]`).forEach((link) => {
      link.addEventListener('click', (event) => {
        event.preventDefault()

        const url = new URL(link.href, window.location.origin)

        if (Alpine.store('currentMember')) {
          window.location = url
        } else {
          Alpine.store('requestedProtectedAsset', url)
        }
      })
    })
  }
  /**
   * Retrieve the previously stored URL if there is one, falls back to the site root
   * @return {URL}
   */
  #retrieveUrl(fallbackUrl = '/') {
    return new URL(window.localStorage.getItem(this.returnUrlKey) || fallbackUrl, window.location.origin)
  }
  /**
   * Subscribe to a namespaced event
   * @param  {String} eventKey
   * @param  {Function} callback
   */
  #subscribe(eventKey, callback) {
    this.#eventBus.on(`MemberAuth:${eventKey}`, callback)
  }
  /**
   * Subscribe a namespaced event
   * @param  {String} eventKey
   * @param  {Object} data to pass with the event
   */
  #emit(eventKey, data) {
    this.#eventBus.emit(`MemberAuth:${eventKey}`, data)
  }
}
