import Alpine from 'alpinejs'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';


/**
 * Manages: main nav state + modal visibility
 *
 * @property $refs
 * @property $store
 */
export default ({
  memberCenterIsOpen,
  modalRef = null,
  }) => {
  return {
    modalRef,
    $modal: null,
    $contentContainer: null,
    init() {
      this.$modal = this.$refs[this.modalRef]

      if (typeof memberCenterIsOpen === 'undefined') {
        memberCenterIsOpen = (new URLSearchParams(window.location.search).get('mc') === 'true')
      }

      if (this.$modal) {
        this.$contentContainer = this.$modal.querySelector('[data-modal-content]')
        this.setupModalStore({memberCenterIsOpen});
        this.bindModalEvents();
        this.bindModalEffect();
      }
    },

    toggleMemberCenter(isOpen = null) {
      this.$store.memberCenterIsOpen = isOpen ?? !this.$store.memberCenterIsOpen
    },

    toggleMobileNav(isOpen = null) {
      this.$store.mobileNavIsOpen = isOpen ?? !this.$store.mobileNavIsOpen
      this.$store.memberCenterIsOpen = false
    },

    openModal(templateSelector) {
      this.setModalContent(document.querySelector(templateSelector))
      this.$store.globalModalIsOpen = true
    },

    closeModal() {
      this.$store.globalModalIsOpen = false
    },

    setModalContent(template) {
      const content = template.content.firstElementChild.cloneNode(true)
      this.clearModalContent()
      this.$contentContainer.appendChild(content)
    },

    clearModalContent() {
      this.$contentContainer.innerHTML = ''
    },

    bindModalEvents() {
      this.$modal.addEventListener('close', () => {
        this.$store.globalModalIsOpen = false
      })
    },

    bindModalEffect() {
      Alpine.effect(() => {
        if (this.$modal) {
          const isOpen = this.$store.globalModalIsOpen

          if (isOpen) {
            this.$modal.showModal()
            disableBodyScroll(this.$modal)
          } else {
            this.$modal.close()
            this.clearModalContent()
            enableBodyScroll(this.$modal)
          }
        }
      })
    },

    setupModalStore(state) {
      Alpine.store('memberCenterIsOpen', state.memberCenterIsOpen)
      Alpine.store('mobileNavIsOpen', false)
      Alpine.store('globalModalIsOpen', false)
    }
  }
}
