import Splide from '@splidejs/splide';

export default class Carousels {
  /**
   * @property array carousels
   *
   */
  instances = {}
  /**
   * @property EventBus #eventBus
   */
  #eventBus

  constructor(eventBus) {
    this.#eventBus = eventBus
    this.bind()
  }

  bind() {
    this.#eventBus.on('carousels:init', (config) => this.initCarousel(config))
    this.#eventBus.on('carouselsProgress:init', (config) => this.initCarouselProgress(config))
  }

  initCarousel({el, options = {}}) {
    this.instances[el] = new Splide(el, {
      pagination: false,
      ...options
    }).mount();
  }

  initCarouselProgress({el, elBar, options = {}}) {
    let splide = new Splide(el, {
      pagination: false,
      arrows: false,
      ...options
    });

    const progressBar = splide.root.querySelector(elBar);

    splide.on('mounted move', function () {
      let end =  splide.Components.Controller.getEnd() + 1;
      let rate = Math.min( ( splide.index + 1 ) / end, 1 );
      progressBar.style.width = String( 100 * rate ) + '%';
    });

    splide.mount();

    this.instances[el] = splide
  }
}
