/**
 * Shared Event Bus for inter-module communication
 */
export default class EventBus {
  subscriptions = {}

  /**
   * Subscribe to a message
   *
   * @param  {String} Message name
   * @param  {Function} Callback
   */
  on(message, callback) {
    if (!this.subscriptions[message]) {
      this.subscriptions[message] = []
    }
    if (typeof callback === 'function') {
      this.subscriptions[message].push(callback)
    }
  }

  /**
   * Publish a message
   *
   * @param  {String} Message name
   * @param  {Mixed} Data to pass with the event to registered callbacks
   */
  emit(message, data) {
    if (this.subscriptions[message]) {
      this.subscriptions[message].forEach((fn) => fn(data))
    }
  }
}
