/**
 * Manages Messaging Modal
 *
 * @see src/templates/layout/skeleton.twig
 * @see https://alpinejs.dev/component/headless-dialog/docs
 * @see src/templates/_partials/global/messageModal.twig
 *
 * @property $refs
 */
export default ({
  debug = window?.APP?.CONFIG?.DEBUG || false,
  endTimestamp,
  startTimestamp,
  }) => {
  return {
    // $dialog - magic property for Alpine Dialog
    debug: debug,
    endTimestamp, // Optional unix timestamp for when the message should stop showing
    isDialogOpen: false, // used for x-model on the dialog
    now: new Date().getTime() / 1000,
    showItAgainAfter: 60 * 60 * 24, // 1 day in seconds
    startTimestamp, // Unix timestamp for when the message should start showing
    init() {
      if (this.debug) {
        console.log('MessageModal', {
          willShow: this.isEnabled && this.userHasNotSeen,
          userHasNotSeen: this.userHasNotSeen,
          isEnabled: this.isEnabled,
          now: this.now,
          startTimestamp: this.startTimestamp,
          endTimestamp: this.endTimestamp,
        })
      }

      this.isDialogOpen = this.isEnabled && this.userHasNotSeen

      // Do side effects for modal closing
      this.$watch('isDialogOpen', () => {
        if (!this.isDialogOpen) {
          this.handleClose()
        }
      })
    },
    get shownAt() { 
      return `messageModalExpiry-${this.startTimestamp}-${this.endTimestamp ?? 'never'}`
    },
    get isEnabled() {
      return (this.startTimestamp < this.now) && (!this.endTimestamp || this.endTimestamp > this.now)
    },
    get userHasNotSeen() {
      const shownAt = Number(localStorage.getItem(this.shownAt))

      if (!shownAt) {
        return true
      }

      return (shownAt + this.showItAgainAfter) < this.now
    },
    handleClose() {
      localStorage.setItem(this.shownAt, this.now)
    },
  }
}
