import Alpine from 'alpinejs'

export default () => ({
  handleSmoothScroll(target) {
    const motionQuery = matchMedia('(prefers-reduced-motion: no-preference)')

    if (motionQuery.matches) {
      target.scrollIntoView({
        behavior: 'smooth',
      })
    } else {
      target.scrollIntoView()
    }
  },

  handleClick(e) {
    e.preventDefault()

    const target = document.querySelector(e.target.hash)

    if (!target) {
      return
    }

    this.handleSmoothScroll(target)
  },

  handleChange(e) {
    const target = document.getElementById(e.target.value)

    if (!target) {
      return
    }

    this.handleSmoothScroll(target)
  }
})
